* {
    box-sizing: border-box;
    padding: 0;
}

.bod{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:50%;
    background:transparent; 
    /* rgb(42, 130, 165); */
}

.wrapper{
    display: inline-flex;
}
.wrapper .static-txt{
    color: rgb(35, 226, 51);
    font-size: 60px;
    font-weight: 400;
    font-family: cursive;
}
.wrapper .dynamic-txt{
    margin-left: 15px;
    line-height:90px;
    /* background: red; */
    height: 90px;
    overflow: hidden;
}
.dynamic-txt li{
    list-style: none;
    font-size: 60px;
    font-weight: 500; 
    color: skyblue;
    position: relative;
    top: 0;
    animation: slide 6s steps(4) infinite;
}
@keyframes slide{
    100%{
        top: -360px;
    }
}

.dynamic-txt li span {
    position: relative;
}

.dynamic-txt li span::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-left: 5px solid skyblue;
    left: 0;
    animation: typing 1.5s steps(12) infinite;
    background:transparent;
}

@keyframes typing {
    100%{
        left: 100%;
        margin: 0 -35px 0 35px;
    }
}



/* 
.bod {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	
	animation: gradient 15s ease infinite;
	
} 

 @keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}*/
