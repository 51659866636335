@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .icon{
        @apply flex h-7 font-medium sm:font-semibold text-lg cursor-pointer 
        bg-clip-text bg-gradient-to-r from-green-300 to-blue-600 hover:from-blue-600 hover:to-green-300
    },
    .logo{
        @apply flex text-lg sm:text-xl md:text-2xl font-bold italic font-sans text-transparent  cursor-pointer  
        transition duration-700 ease-in-out  animate-pulse 
        bg-clip-text bg-gradient-to-r from-blue-600 via-purple-800  to-green-300 hover:from-blue-600 hover:to-green-300
    },

    .textArea{
        @apply  focus:bg-white outline-none resize-y  mb-5 py-2 px-3 text-gray-700 placeholder-gray-500 focus:placeholder-gray-400
        border rounded  focus:shadow-lg ring-2 ring-blue-200 ring-offset-1 ring-offset-green-200 focus-within:ring-opacity-0 focus:ring-offset-0
    },
    .card{
        @apply bg-gray-500            
    }
}